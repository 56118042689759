import { init_language } from '@/utils/language'

const config = {
  host  : window.location.hostname,
  lang  : init_language('en'),
  email : 'support@finda.net',
  set_email (src: string): string {
    return src.replaceAll('support@finda.net', this.email)
  },
  gtag: '',
  stripe: 'pk_live_51MFeGFK2UMrYUtMH7j26OjeoY1K4JAAqZiJtlNjop6Ywek9h3RJQM7JV4mlRbB4sl7OphZVoiyxvxRJQgGZR9CkK00Mo5fZs0c'
}

export { config }
  
